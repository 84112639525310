import Layout from '../components/layout';
import Paper from '../components/paper';
import React, { ReactElement } from 'react';

export default function Privacidade(): ReactElement {
  return (
    <Layout title="Privacidade" gray>
      <Paper title="Política de privacidade">
        <h2 className="topic">
          <b>
            Política de privacidade da SysRS
          </b>
        </h2>
        <p>
          Todas as informações pessoais relativas a colaboradores, clientes, fornecedores, parceiros, candidatos a
          emprego
          ou quaisquer outros terceiros que utilizem os serviços da SysRS Tecnologia da Informação LTDA. serão tratadas
          em
          concordância com a Lei Geral de Proteção de Dados (LGPD) <b>nº 13.709, de 14 de agosto de 2018</b>. A
          utilização dos
          produtos da SysRS pressupõe o aceite dessa Política de Privacidade. A SysRS reserva-se ao direito de alterar
          este acordo sem aviso prévio. Portanto, recomendamos que consulte a nossa Política de Privacidade com
          regularidade, a fim de manter-se atualizado. Você não deve utilizar nossos serviços caso não concorde com essa
          política.
        </p>
        <p>
          A SysRS e seus colaboradores se comprometem a garantir a confidencialidade de seus dados.
        </p>

        <h2 className="topic">
          <b>
            Papéis dentro da LGPD
          </b>
        </h2>
        <p>
          De acordo com a lei nº 13.709, a LGPD dispõe sobre o tratamento de dados pessoais, inclusive nos meios
          digitais,
          por pessoa natural ou por pessoa jurídica de direito público ou privado, com o objetivo de proteger os
          direitos
          fundamentais de liberdade e de privacidade e o livre desenvolvimento da personalidade da pessoa natural. Essa
          lei considera que:
        </p>
        <p>
          1. <b>Dado pessoal</b> é uma informação relacionada a pessoa natural identificada ou identificável;
        </p>
        <p>
          2. <b>Dado pessoal sensível</b> é um dado pessoal sobre origem racial ou étnica, convicção religiosa, opinião
          política,
          filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dado referente à saúde ou
          à
          vida sexual, dado genético ou biométrico, quando vinculado a uma pessoa natural;
        </p>
        <p>
          3. <b>Titular</b> é a pessoa natural a quem se referem os dados pessoais que são objeto de tratamento;
        </p>
        <p>
          4. <b>Controlador</b> é a pessoa natural ou jurídica, de direito público ou privado, a quem competem as
          decisões
          referentes ao tratamento de dados pessoais;
        </p>
        <p>
          5. <b>Operador</b> é a pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de
          dados
          pessoais em nome do controlador.
        </p>

        <h2 className="topic">
          <b>
            Por quê a SysRS coleta seus dados?
          </b>
        </h2>
        <p>
          Todas as informações coletadas pela SysRS em nossas plataformas possuem justificativas legítimas e o
          tratamento
          dos mesmos se dará conforme capítulo III da Lei Geral de Proteção de Dados.
        </p>
        <p>
          - Para o cumprimento de uma obrigação legal ou regulatória. Exemplos: emissão de notas fiscais; geração de
          documentos de cobrança, obrigações trabalhistas; declarações à autoridade de fiscalização de tributos;
          cumprimento de obrigações previstas no Código de Defesa do Consumidor (CDC).
        </p>
        <p>
          - Definição e execução dos procedimentos relacionados à contratação de serviços da SysRS;
        </p>
        <p>
          - Para o exercício regular dos direitos da SysRS em processo judicial, administrativo e também para a proteção
          do crédito;
        </p>
        <p>
          - Também podemos coletar e tratar seus dados pessoais para que possamos entrar em contato com você, exceto
          quando infringirem os seus interesses ou direitos e liberdades fundamentais.
        </p>

        <h2 className="topic">
          <b>
            Quais dados são coletados?
          </b>
        </h2>
        <p>
          A SysRS coleta informações de forma indireta, ou seja, quando os dados da sua sessão são apurados pela
          plataforma que você está acessando, bem como de forma direta, quando você explicitamente passa alguma
          informação
          para nós.
        </p>
        <p>
          Ao acessar os nossos ambientes Web e Mobile, coletamos o seu Endereço IP, bem como dados do dispositivo que
          está
          usando, visando melhorar sua experiência ao usar os serviços da SysRS, além de mitigar e/ou conter ataques
          cibernéticos provenientes de agentes maliciosos.
        </p>
        <p>
          Você também poderá passar informações de forma direta com a SysRS, ao enviar e-mails através da opção
          ?Contato?
          do nosso website.
        </p>

        <h2 className="topic">
          <b>
            Compartilhamento de Dados Pessoais
          </b>
        </h2>
        <p>
          Ao utilizar o nosso Sistema de Gestão Empresarial (ERP), é possível que a SysRS tenha que compartilhar
          informações com órgãos públicos, reguladores e entidades bancárias. Isso é necessário para que possamos
          executar
          procedimentos fundamentais para manutenção das funcionalidades do sistema, bem como atender às necessidades
          dos
          titulares dos dados.
        </p>
        <p>
          No que tange o compartilhamento dos dados de nossos clientes, poderemos tratá-los nos seguintes casos:
        </p>
        <p>
          - Processos de homologação com a Receita Federal, como a emissão de Documentos Fiscais Eletrônicos (DF-e) no
          ambiente de testes do próprio órgão;
        </p>
        <p>
          - Processos de homologação com bancos. Ao contratar os serviços com entidades financeiras, é necessário enviar
          boletos de cobrança de teste já com dados verdadeiros, a fim de garantir que as informações estejam corretas;
        </p>
        <p>
          - Integração com o Sistema Público de Escrituração Digital (SPED);
        </p>
        <p>
          - Integrações com sistemas de terceiros, desde que isso seja solicitado pelo próprio cliente mediante
          consentimento.
        </p>
        <p>
          Em nosso ERP, também podem existir dados relacionados com fornecedores ou clientes dos nossos parceiros. Para
          a
          SysRS, estas informações são tão importantes quanto as relacionadas diretamente com nossos clientes. Portanto,
          o
          seu compartilhamento também seguirá as mesmas diretrizes dispostas nesta Política de Privacidade. Nunca
          comercializaremos qualquer dado pessoal seu.
        </p>
        <p>
          Em relação aos colaboradores, suas informações poderão ser compartilhadas com a Assessoria de Contabilidade e
          também com o fornecedor responsável pelo Vale Alimentação.
        </p>

        <h2 className="topic">
          <b>
            Revogação de Consentimento
          </b>
        </h2>
        <p>
          A qualquer momento você poderá entrar em contato com a SysRS para solicitar o cancelamento do consentimento.
        </p>

        <h2 className="topic">
          <b>
            Ligação a serviços de terceiros
          </b>
        </h2>
        <p>
          A SysRS possui ligações para outros sites, os quais podem conter informações úteis para os nossos visitantes.
          A
          Política de Privacidade da SysRS não se aplica a sites de terceiros. Portanto, caso visite outra página Web a
          partir da nossa, deverá ler a política de privacidade do mesmo. Não nos responsabilizamos pela política de
          privacidade ou conteúdo presente nesses sites.
        </p>
        <p>
          Os respectivos produtos da SysRS poderão enviar e-mails, quando requisitados pelo usuário, em seu nome,
          utilizando a Google API, com a finalidade de transmitir informações e anexos a destinatários informados pelo
          mesmo. Nenhuma outra informação de sua conta Google é coletada. As informações de sua conta Google não são
          compartilhadas com terceiros.
        </p>

        <h2 className="topic">
          <b>
            Contato
          </b>
        </h2>
        <p>
          Caso possuam alguma dúvida, entre em contato com o nosso Encarregado da Proteção de Dados:<br /><br />
          <b>Encarregado de Proteção de Dados - DPO</b><br />
          Fernando Augusto Schuch<br />
          <a href="mailto:dpo@sysrs.com.br">dpo @sysrs.com.br</a>
        </p>
      </Paper>
    </Layout>
  )
}
